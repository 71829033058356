import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import TestResult from "../../components/test-result";


const CmpUiShownPage = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
      setResult(false);
      window.__tcfapi = function (command, version, callback) {
        if (command == 'addEventListener') {
          const tcData = {eventStatus: "cmpuishown"};
          callback(tcData, true);
          setResult(true);
        }
      };

      const iframe = document.createElement('iframe');
      iframe.name = '__tcfapiLocator';
      iframe.width = '1px';
      iframe.height = '1px';
      document.body.appendChild(iframe);
    }, []);

  return (
    <Layout>
      <h1>Consent Management API</h1>
      <h2>Check for CMP events listener</h2>
      <p>
        This test verifies if an event listener was injected to handle <code>cmpuishown</code> events
        for a <a href="https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md">CMP API</a> implementation to be able to send callbacks.
      </p>
      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Test Result</h3>
          <TestResult result={result} colorStyle="bg">
            <div className="border p-4 text-white">
              {result ? 'Passed' : 'Failed'}
            </div>
          </TestResult>
        </div>
      </div>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Consent Management API"/>
export default CmpUiShownPage;
